import React from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Works } from "./components/works";
import { MoreFeatures } from "./components/more";
import { Faq } from "./components/faq";
import { Footer } from "./components/footer";
import "./index.module.scss";

function App() {
  return (
    <div className="app">
      <Header />
      <Features />
      <Works />
      <MoreFeatures />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
