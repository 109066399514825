import styles from "./index.module.scss";
import Logo from "./../../assets/Dropdefi Logo_PNG 1.png";
import { Navbutton } from "../button/navButton";

export const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <img src={Logo} alt="dropdefi logo" />
      <Navbutton />
    </div>
  );
};
