import styles from "./index.module.scss";
import { ReactNode } from "react";

interface WorkProps {
  number: number;
  img: string;
  title: string;
}

export const Work = (props: WorkProps) => {
  return (
    <div className={styles.work}>
      <p className={styles.work_number}>{props.number}</p>
      <img src={props.img} alt="how it works" className={styles.work_img} />
      <p className={styles.work_title}>{props.title}</p>
      <small className={styles.work_small}>
        Create an account on our platform to get started
      </small>
    </div>
  );
};
