import { Button } from "../button/button";
import { Navbar } from "../navbar";
import styles from "./index.module.scss";
import asset1 from "./../../assets/asset1.png";
import asset2 from "./../../assets/asset2.png";
import heroimg from "./../../assets/heroimg.png";

export const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.header_main}>
        <Navbar />
        <div className={styles.header_herotext}>
          <h1>No-Code Solution to Build Seamless Blockchain interactions</h1>
          <small>Onboarding users one template at a time.</small>
          <Button />
        </div>
        <img src={asset1} alt="asset1" className={styles.header_asset1} />
        <img src={asset2} alt="asset1" className={styles.header_asset2} />
        <div className={styles.header_heroimg}>
          <img src={heroimg} alt="heroimg" />
        </div>
      </div>
    </div>
  );
};
