export default function PlayButton() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 9.32993V14.6699C6.5 17.9899 8.85 19.3399 11.72 17.6899L13 16.9499C13.31 16.7699 13.5 16.4399 13.5 16.0799V7.91993C13.5 7.55993 13.31 7.22993 13 7.04993L11.72 6.30992C8.85 4.65992 6.5 6.00993 6.5 9.32993Z"
        fill="#fff"
      />
      <path
        d="M14.5 8.79006V15.2201C14.5 15.6101 14.92 15.8501 15.25 15.6501L16.35 15.0101C19.22 13.3601 19.22 10.6401 16.35 8.99006L15.25 8.35006C14.92 8.16006 14.5 8.40006 14.5 8.79006Z"
        fill="#fff"
      />
    </svg>
  );
}
