import styles from "./index.module.scss";
import { Work } from "./work";
import Work1 from "./../../assets/works1.png";
import Work2 from "./../../assets/works2.png";
import Work3 from "./../../assets/works3.png";

export const Works = () => {
  return (
    <div className={styles.works}>
      <h2>How it works</h2>
      <div className={styles.works_div}>
        <Work number={1} img={Work1} title={"Select a template action"} />
        <Work number={2} img={Work2} title={"Fill in required arguments"} />
        <Work number={3} img={Work3} title={"Run selected action"} />
      </div>
    </div>
  );
};
