export default function DevModeIcon() {
  return (
    <svg
      width="370"
      height="197"
      viewBox="0 0 370 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M128.564 163.122C128.472 163.161 65.2412 132.013 65.2412 132.013V112.603L128.564 143.713"
        fill="#E8E8EC"
      />
      <path
        d="M128.564 163.122C128.472 163.161 65.2412 132.013 65.2412 132.013V112.603L128.564 143.713"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M65.3335 33.2445L58.1241 36.8446L50.134 32.9315C50.0881 32.9315 50.0881 32.8923 50.134 32.8532L57.3894 29.2531L65.3335 33.2445Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M50.1337 33.0097C50.1337 33.9489 50.0878 41.2665 50.1337 41.2665C50.1337 41.2665 57.389 44.8274 58.0778 45.1796C58.0778 45.1796 58.1238 45.1796 58.1238 45.1405V36.8446L50.2715 32.9706C50.1796 32.9315 50.1337 32.9706 50.1337 33.0097Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M58.1694 45.1794L65.3329 41.6184V33.3226C65.3329 33.2834 65.287 33.2834 65.2411 33.2834L58.1235 36.8444V45.1403C58.0776 45.1794 58.1235 45.1794 58.1694 45.1794Z"
        fill="#9696A8"
      />
      <path
        d="M201.393 107.516L128.565 143.712C128.473 143.752 65.2415 112.603 65.2415 112.603C65.0119 112.486 65.0119 112.212 65.2415 112.055C65.2415 112.055 138.345 75.8194 138.437 75.8585C138.437 75.8585 201.669 107.359 201.393 107.516Z"
        fill="#9696A8"
      />
      <path
        d="M128.564 163.082V143.673L201.163 107.594C201.255 107.555 201.393 107.594 201.393 107.711V126.886L128.564 163.082Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M201.393 116.868L128.564 153.065C128.472 153.104 65.2412 121.956 65.2412 121.956"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M95.3185 5.26533L42.4651 31.4443C42.4192 31.4443 42.4192 31.4443 42.3733 31.4443L33.97 27.2963C33.9241 27.2572 33.9241 27.1789 33.97 27.1789L86.8234 1C86.8694 1 86.8693 1 86.9152 1L95.3185 5.14793C95.3645 5.14793 95.3645 5.2262 95.3185 5.26533Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M42.2813 91.6286L33.832 87.4416V27.3356C33.832 27.2965 33.9239 27.2574 33.9698 27.2574L42.4191 31.4445V91.5504C42.4191 91.6286 42.3731 91.6286 42.2813 91.6286Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M86.3649 49.6407C82.0484 63.0628 67.4919 73.9805 58.4457 71.8674C49.3996 69.7543 47.425 57.1931 51.7414 43.771C56.0579 30.3489 71.2573 20.8791 80.2575 22.9922C89.2578 25.1053 90.6813 36.2186 86.3649 49.6407Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M95.2723 5.30484L42.4648 31.4446C42.4189 31.4446 42.4189 31.4838 42.4189 31.5229V91.5114C42.4189 91.5897 42.5107 91.6288 42.6026 91.5897L95.4101 65.4499C95.456 65.4499 95.456 65.4107 95.456 65.3716V5.3831C95.456 5.30484 95.3641 5.26571 95.2723 5.30484ZM86.3639 49.6408C82.0475 63.0629 67.491 73.9805 58.4448 71.8674C49.4446 69.7543 47.4241 57.1931 51.7405 43.7711C54.9549 33.7534 64.2307 25.9662 72.4963 23.501C75.2973 22.6792 78.0066 22.4444 80.3026 22.9923C89.3028 25.1054 90.6804 36.2187 86.3639 49.6408Z"
        fill="#9696A8"
      />
      <path
        d="M56.3789 71.1235C64.4607 68.2669 73.3691 59.658 76.5835 49.6403C80.5785 37.1574 79.6602 26.6702 72.2671 23.5788"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M80.303 22.992C78.0071 22.4442 75.3437 22.679 72.4967 23.5008C72.4049 23.5399 72.359 23.5399 72.2671 23.579C79.6602 26.6704 80.5785 37.1576 76.5835 49.6406C73.3691 59.6582 64.4148 68.2671 56.3789 71.1237C57.0218 71.4368 57.7106 71.7107 58.4913 71.8672C67.4915 73.9803 82.0479 63.0626 86.4102 49.6406C90.6808 36.2185 89.3033 25.1051 80.303 22.992Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M102.298 42.0882L87.7879 49.2884C87.7419 49.2884 71.762 41.423 71.762 41.423C71.7161 41.3838 71.7161 41.3056 71.762 41.3056C71.762 41.3056 86.3185 34.0663 86.3644 34.1054C86.3644 34.0663 102.344 42.0491 102.298 42.0882Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M71.7616 41.423C71.7616 41.423 71.7157 58.2104 71.7616 58.2496C71.7616 58.2496 86.364 65.4497 87.6957 66.0758C87.7416 66.115 87.7875 66.0758 87.7875 66.0367V49.2885L71.7616 41.423Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M96.1917 228.98C96.0998 229.019 55.2314 208.866 55.2314 208.866V182.022L96.1917 202.136"
        fill="#E8E8EC"
      />
      <path
        d="M96.1917 228.98C96.0998 229.019 55.2314 208.866 55.2314 208.866V182.022L96.1917 202.136"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M87.9259 66.0365L102.299 58.8754V42.0489L87.7881 49.2491V65.9582C87.7881 66.0365 87.8799 66.0756 87.9259 66.0365Z"
        fill="#9696A8"
      />
      <path
        d="M96.1914 228.902V202.097L143.121 178.775C143.213 178.735 143.305 178.775 143.305 178.853V205.54L96.2374 228.941C96.1915 228.941 96.1914 228.941 96.1914 228.902Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M69.7875 57.4277L62.5781 61.0278L54.5882 57.1146C54.5422 57.1146 54.5422 57.0755 54.5882 57.0364L61.8433 53.4363C61.8433 53.4363 69.8334 57.3886 69.7875 57.4277Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M54.5878 57.1929C54.5878 58.1321 54.5419 65.4496 54.5878 65.4496C54.5878 65.4496 61.843 69.0106 62.5318 69.3628C62.5318 69.3628 62.5777 69.3628 62.5777 69.3237V61.0278L54.7255 57.1538C54.6795 57.1147 54.5878 57.1538 54.5878 57.1929Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M62.6241 69.3237L69.7875 65.7627V57.4669C69.7875 57.4277 69.7416 57.4277 69.6957 57.4277L62.5781 60.9887V69.2846C62.5781 69.3237 62.6241 69.3628 62.6241 69.3237Z"
        fill="#9696A8"
      />
      <path
        d="M143.305 178.696L96.1915 202.097C96.0996 202.136 55.2312 181.983 55.2312 181.983C55.0935 181.905 55.0935 181.709 55.2312 181.631C55.2312 181.631 102.528 158.191 102.574 158.23C102.574 158.23 143.443 178.618 143.305 178.696Z"
        fill="#9696A8"
      />
      <path
        d="M143.305 193.253L96.1917 216.653C96.0998 216.692 55.2314 196.54 55.2314 196.54"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M129.85 175.487V176.191C129.85 176.974 129.345 177.718 128.564 178.109C122.732 180.966 99.2674 192.627 93.1142 195.64C91.8744 196.266 90.359 196.266 89.1651 195.64C84.7568 193.448 71.3024 186.796 67.0319 184.683C66.2053 184.292 65.7461 183.548 65.7461 182.766V182.1L97.4306 176.935L129.666 175.252C129.758 175.291 129.85 175.37 129.85 175.487Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M93.1144 193.996C100.094 190.514 123.605 178.892 129.391 176.035C129.988 175.761 129.988 175.018 129.391 174.744C125.258 172.67 112.355 166.291 107.028 163.67C105.559 162.965 103.768 162.965 102.344 163.67C94.7676 167.387 71.073 179.166 66.0219 181.631C65.6086 181.827 65.6086 182.335 66.0219 182.57C69.7873 184.409 83.8846 191.414 89.1194 193.996C90.3593 194.583 91.8746 194.583 93.1144 193.996Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M214.297 172.2L194.276 182.14C194.23 182.14 176.872 173.57 176.872 173.57C176.826 173.531 176.826 173.452 176.872 173.413C176.872 173.413 196.985 163.435 197.031 163.474C196.939 163.474 214.343 172.161 214.297 172.2Z"
        fill="#9696A8"
      />
      <path
        d="M71.8997 182.179C76.6754 179.831 98.3035 169.07 105.559 165.509C105.651 165.47 105.651 165.392 105.559 165.352C105.191 165.157 104.411 164.805 104.089 164.609C104.043 164.609 103.997 164.609 103.997 164.609C96.9259 168.092 75.3436 178.775 70.3384 181.279C70.2466 181.318 70.2466 181.396 70.3384 181.435C70.7517 181.631 71.5324 182.022 71.8079 182.179C71.8079 182.218 71.8538 182.218 71.8997 182.179Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M74.2873 183.353C79.063 181.005 100.691 170.244 107.946 166.683C108.038 166.644 108.038 166.566 107.946 166.526C107.579 166.331 106.798 165.979 106.477 165.783C106.431 165.783 106.385 165.783 106.385 165.783C99.3134 169.266 77.7313 179.948 72.7261 182.453C72.6343 182.492 72.6343 182.57 72.7261 182.609C73.1394 182.805 73.92 183.196 74.1955 183.353C74.1955 183.392 74.2414 183.392 74.2873 183.353Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M76.5832 184.605C81.3589 182.257 102.987 171.496 110.242 167.935C110.334 167.895 110.334 167.817 110.242 167.778C109.875 167.582 109.094 167.23 108.773 167.035C108.727 167.035 108.681 167.035 108.681 167.035C101.609 170.517 80.0272 181.2 75.022 183.705C74.9302 183.744 74.9302 183.822 75.022 183.861C75.4353 184.057 76.2159 184.448 76.4914 184.605C76.5373 184.605 76.5832 184.605 76.5832 184.605Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M198.776 167.153L202.22 168.835L197.398 171.222L193.954 169.54L198.776 167.153ZM197.398 170.518L200.842 168.835L199.464 168.17L196.02 169.853L197.398 170.518Z"
        fill="#DFDFF4"
      />
      <path
        d="M204.975 170.205L200.199 172.553L198.821 171.888L202.219 170.205L201.531 169.853L202.219 169.501L202.908 169.853L203.597 169.501L204.975 170.205Z"
        fill="#DFDFF4"
      />
      <path
        d="M205.663 170.518L209.107 172.201L204.286 174.588L200.842 172.905L205.663 170.518ZM204.332 173.923L207.776 172.24L206.398 171.575L202.954 173.257L204.332 173.923Z"
        fill="#DFDFF4"
      />
      <path
        d="M198.087 166.8L193.265 169.187L191.888 168.483L195.332 166.8L194.643 166.448L195.332 166.135L196.02 166.448L196.709 166.135L198.087 166.8Z"
        fill="#DFDFF4"
      />
      <path
        d="M79.0637 185.818C83.8393 183.47 105.467 172.709 112.723 169.148C112.815 169.109 112.815 169.031 112.723 168.992C112.355 168.796 111.575 168.444 111.253 168.248C111.207 168.248 111.162 168.248 111.162 168.248C104.09 171.731 82.5077 182.414 77.5025 184.918C77.4106 184.957 77.4106 185.036 77.5025 185.075C77.9157 185.27 78.6964 185.662 78.9719 185.818C78.9719 185.818 79.0178 185.818 79.0637 185.818Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M81.4515 187.07C86.2271 184.722 107.855 173.961 115.11 170.4C115.202 170.361 115.202 170.283 115.11 170.244C114.743 170.048 113.962 169.696 113.641 169.5C113.595 169.5 113.549 169.5 113.549 169.5C106.478 172.983 84.8954 183.666 79.8902 186.17C79.7983 186.209 79.7983 186.288 79.8902 186.327C80.3034 186.522 81.0841 186.914 81.3596 187.07C81.3596 187.07 81.4056 187.07 81.4515 187.07Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M265.864 147.43C265.772 147.469 224.903 127.317 224.903 127.317V100.472L265.864 120.586"
        fill="#E8E8EC"
      />
      <path
        d="M265.864 147.43C265.772 147.469 224.903 127.317 224.903 127.317V100.472L265.864 120.586"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M83.885 188.244C88.6606 185.896 110.289 175.135 117.544 171.574C117.636 171.535 117.636 171.457 117.544 171.418C117.177 171.222 116.396 170.87 116.075 170.674C116.029 170.674 115.983 170.674 115.983 170.674C108.911 174.157 87.329 184.84 82.3238 187.344C82.2319 187.383 82.2319 187.462 82.3238 187.501C82.737 187.696 83.5176 188.088 83.7932 188.244C83.7932 188.283 83.8391 188.283 83.885 188.244Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M86.2728 189.496C91.0484 187.148 89.3034 187.97 96.5128 184.409C96.6046 184.37 96.6046 184.292 96.5128 184.253C96.1454 184.057 95.3648 183.705 95.0434 183.509C94.9974 183.509 94.9515 183.509 94.9515 183.509C87.8799 186.992 89.7167 186.092 84.7115 188.596C84.6196 188.635 84.6196 188.714 84.7115 188.753C85.1247 188.949 85.9053 189.34 86.1809 189.496C86.1809 189.536 86.2269 189.536 86.2728 189.496Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M97.5686 183.94C102.344 181.592 101.38 182.022 108.589 178.461C108.681 178.422 108.681 178.344 108.589 178.305C108.222 178.109 107.441 177.757 107.12 177.561C107.074 177.561 107.028 177.561 107.028 177.561C99.9564 181.044 101.013 180.574 96.0074 183.04C95.9155 183.079 95.9155 183.157 96.0074 183.196C96.4206 183.392 97.2012 183.783 97.4768 183.94C97.4768 183.94 97.5227 183.94 97.5686 183.94Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M109.646 177.992C114.421 175.644 112.676 176.465 119.886 172.904C119.978 172.865 119.978 172.787 119.886 172.748C119.518 172.552 118.738 172.2 118.416 172.004C118.37 172.004 118.324 172.004 118.324 172.004C111.253 175.487 113.09 174.587 108.085 177.092C107.993 177.131 107.993 177.209 108.085 177.248C108.498 177.444 109.278 177.835 109.554 177.992C109.554 177.992 109.6 177.992 109.646 177.992Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M103.676 187.305C108.452 184.957 107.992 185.153 115.248 181.592C115.339 181.553 115.339 181.475 115.248 181.435C114.88 181.24 110.242 178.892 109.921 178.735C109.875 178.735 109.829 178.735 109.829 178.735C102.757 182.218 103.263 181.944 98.2574 184.448C98.1655 184.488 98.1655 184.566 98.2574 184.605C98.6706 184.801 103.309 187.188 103.584 187.305C103.63 187.344 103.676 187.344 103.676 187.305Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M103.355 136.747L102.666 136.395C101.839 136.004 100.875 136.004 100.048 136.395C94.2167 139.252 70.7517 150.913 64.5985 153.926C63.3586 154.513 62.624 155.648 62.624 156.861C62.6699 161.205 62.7617 174.47 62.8077 178.657C62.8077 179.44 63.3127 180.183 64.1393 180.575L64.8281 180.888L85.7674 159.991L103.446 136.943C103.492 136.904 103.446 136.787 103.355 136.747Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M368.999 179.596L321.978 202.957C321.886 202.996 281.109 182.883 281.109 182.883C280.971 182.805 280.971 182.609 281.109 182.531C281.109 182.531 328.269 159.13 328.36 159.169C328.36 159.169 369.183 179.517 368.999 179.596Z"
        fill="#9696A8"
      />
      <path
        d="M265.864 147.391V120.586L312.794 97.2634C312.886 97.2243 312.978 97.2634 312.978 97.3417V124.029L265.91 147.43L265.864 147.391Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M66.2978 154.787C73.3235 151.343 96.7884 139.721 102.574 136.825C103.171 136.551 103.86 136.904 103.906 137.451C103.952 141.56 104.09 154.278 104.135 159.522C104.135 160.969 103.263 162.261 101.794 163.004C94.2168 166.761 70.5224 178.5 65.4712 180.966C65.058 181.161 64.5528 180.926 64.5528 180.496C64.5528 176.779 64.4151 162.887 64.3232 157.722C64.3232 156.508 65.058 155.374 66.2978 154.787Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M95.1349 150.209L95.1809 149.739L93.9411 150.365C89.0277 152.791 76.859 158.817 73.4609 160.5V160.97C76.859 159.287 89.3032 153.143 94.2166 150.717L95.1349 150.209Z"
        fill="#9696A8"
      />
      <path
        d="M73.415 162.339V162.809C75.0222 162.026 78.558 160.265 82.3694 158.387V157.917C78.558 159.796 75.0222 161.557 73.415 162.339Z"
        fill="#9696A8"
      />
      <path
        d="M83.6543 157.291V157.761C87.6493 155.765 91.7821 153.73 94.1699 152.556L95.1801 152.048L95.2261 151.578L93.9862 152.204C91.5984 153.339 87.5575 155.335 83.6543 157.291Z"
        fill="#9696A8"
      />
      <path
        d="M73.415 163.943V164.413C75.8029 163.239 82.7367 159.796 88.2929 157.056V156.587C82.7826 159.326 75.8488 162.73 73.415 163.943Z"
        fill="#9696A8"
      />
      <path
        d="M89.5791 155.922V156.391C91.37 155.491 92.9771 154.709 94.1711 154.122L95.1813 153.613L95.2272 153.143L93.9873 153.77C92.7934 154.357 91.2781 155.1 89.5791 155.922Z"
        fill="#9696A8"
      />
      <path
        d="M73.415 165.587V166.057C76.8131 164.374 89.2573 158.23 94.1707 155.804L95.1809 155.295L95.2268 154.826L93.9869 155.452C89.0276 157.878 76.8131 163.904 73.415 165.587Z"
        fill="#9696A8"
      />
      <path
        d="M73.415 167.348V167.818C75.0222 167.035 78.558 165.274 82.3694 163.396V162.926C78.558 164.804 75.0222 166.565 73.415 167.348Z"
        fill="#9696A8"
      />
      <path
        d="M83.6543 162.3V162.77C87.6493 160.774 91.7821 158.739 94.1699 157.565L95.1801 157.056L95.2261 156.587L93.9862 157.213C91.5984 158.387 87.5575 160.343 83.6543 162.3Z"
        fill="#9696A8"
      />
      <path
        d="M89.5791 161.087V161.557C91.37 160.657 92.9771 159.874 94.1711 159.287L95.1813 158.778L95.2272 158.309L93.9873 158.935C92.7934 159.483 91.2781 160.226 89.5791 161.087Z"
        fill="#9696A8"
      />
      <path
        d="M73.415 169.07V169.539C75.8029 168.365 82.7367 164.922 88.2929 162.183V161.713C82.7826 164.452 75.8488 167.857 73.415 169.07Z"
        fill="#9696A8"
      />
      <path
        d="M293.278 180.457L321.427 184.879C321.427 184.879 321.473 184.879 321.427 184.839L316.514 177.913L302.279 175.722L293.278 180.457ZM293.278 180.457C293.278 180.418 293.278 180.418 293.278 180.457Z"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M316.56 177.952L298.284 186.992C298.1 187.07 297.87 187.031 297.733 186.874L293.232 180.457"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M302.324 175.722L307.1 182.609"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M189.408 171.105L184.632 173.492L183.255 172.787L186.653 171.105L185.964 170.752L186.653 170.4L187.342 170.752L188.03 170.4L189.408 171.105Z"
        fill="#DFDFF4"
      />
      <path
        d="M190.097 171.457L193.541 173.139L188.719 175.526L185.275 173.844L190.097 171.457ZM188.765 174.822L192.209 173.139L190.832 172.474L187.388 174.157L188.765 174.822Z"
        fill="#DFDFF4"
      />
      <path
        d="M200.475 176.544L195.653 178.892L194.275 178.226L197.719 176.544L197.031 176.192L197.719 175.839L198.408 176.192L199.051 175.839L200.475 176.544Z"
        fill="#DFDFF4"
      />
      <path
        d="M194.23 173.492L197.674 175.174L192.852 177.561L189.408 175.879L194.23 173.492ZM192.898 176.857L196.342 175.174L194.965 174.509L191.521 176.192L192.898 176.857Z"
        fill="#DFDFF4"
      />
      <path
        d="M335.478 192.001L307.421 187.149C307.421 187.149 307.375 187.149 307.421 187.188L312.196 194.153L326.386 196.579L335.478 192.001ZM335.478 192.001C335.524 192.001 335.524 192.04 335.478 192.001Z"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M312.197 194.153L330.657 185.387C330.841 185.309 331.07 185.348 331.208 185.505L335.57 192.001"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M326.386 196.579L321.748 189.613"
        stroke="#FFFDEE"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M312.978 97.1465L265.864 120.547C265.772 120.586 224.904 100.434 224.904 100.434C224.766 100.355 224.766 100.16 224.904 100.081C224.904 100.081 272.201 76.6416 272.247 76.6807C272.247 76.6807 313.161 97.0682 312.978 97.1465Z"
        fill="#9696A8"
      />
      <path
        d="M312.977 111.703L265.864 135.104C265.772 135.143 224.903 114.99 224.903 114.99"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M299.523 93.9374V94.6418C299.523 95.4244 299.018 96.1679 298.237 96.5592C292.405 99.4158 268.94 111.077 262.787 114.09C261.547 114.716 260.032 114.716 258.838 114.09C254.43 111.899 240.975 105.246 236.705 103.133C235.878 102.742 235.419 101.999 235.419 101.216V100.551L267.103 95.3853L299.339 93.7026C299.431 93.7809 299.523 93.8592 299.523 93.9374Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M262.787 112.446C269.767 108.964 293.278 97.3417 299.064 94.4851C299.661 94.2111 299.661 93.4676 299.064 93.1937C294.931 91.1198 282.028 84.7413 276.701 82.1195C275.231 81.4152 273.441 81.4152 272.017 82.1195C264.44 85.837 240.746 97.6156 235.695 100.081C235.281 100.277 235.281 100.785 235.695 101.02C239.46 102.859 253.557 109.864 258.792 112.446C260.032 113.072 261.547 113.072 262.787 112.446Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M241.573 100.668C246.348 98.3199 267.976 87.5588 275.232 83.9978C275.323 83.9587 275.323 83.8804 275.232 83.8413C274.864 83.6456 274.084 83.2934 273.762 83.0978C273.716 83.0978 273.67 83.0978 273.67 83.0978C266.599 86.5805 245.016 97.2634 240.011 99.7678C239.919 99.8069 239.919 99.8852 240.011 99.9243C240.425 100.12 241.205 100.511 241.481 100.668C241.527 100.668 241.573 100.668 241.573 100.668Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M243.96 101.842C248.736 99.4939 270.364 88.7327 277.619 85.1718C277.711 85.1326 277.711 85.0544 277.619 85.0152C277.252 84.8196 276.471 84.4674 276.15 84.2717C276.104 84.2717 276.058 84.2717 276.058 84.2717C268.986 87.7544 247.404 98.4373 242.399 100.942C242.307 100.981 242.307 101.059 242.399 101.098C242.812 101.294 243.593 101.685 243.868 101.842C243.914 101.842 243.96 101.842 243.96 101.842Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M246.302 103.055C251.078 100.707 272.706 89.9459 279.961 86.3849C280.053 86.3458 280.053 86.2676 279.961 86.2284C279.594 86.0328 278.813 85.6806 278.492 85.4849C278.446 85.4849 278.4 85.4849 278.4 85.4849C271.328 88.9676 249.746 99.6505 244.741 102.155C244.649 102.194 244.649 102.272 244.741 102.311C245.154 102.507 245.935 102.898 246.21 103.055C246.21 103.094 246.256 103.094 246.302 103.055Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M248.736 104.268C253.511 101.92 275.139 91.1591 282.395 87.5981C282.486 87.559 282.486 87.4807 282.395 87.4416C282.027 87.246 281.247 86.8938 280.925 86.6981C280.879 86.6981 280.833 86.6981 280.833 86.6981C273.762 90.1808 252.18 100.864 247.174 103.368C247.083 103.407 247.083 103.486 247.174 103.525C247.588 103.72 248.368 104.112 248.644 104.268C248.644 104.307 248.69 104.307 248.736 104.268Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M251.124 105.52C255.9 103.172 277.528 92.4112 284.783 88.8503C284.875 88.8111 284.875 88.7329 284.783 88.6938C284.416 88.4981 283.635 88.1459 283.314 87.9503C283.268 87.9503 283.222 87.9503 283.222 87.9503C276.15 91.433 254.568 102.116 249.563 104.62C249.471 104.659 249.471 104.738 249.563 104.777C249.976 104.972 250.757 105.364 251.032 105.52C251.032 105.559 251.078 105.559 251.124 105.52Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M253.558 106.733C258.333 104.385 279.962 93.6241 287.217 90.0631C287.309 90.024 287.309 89.9457 287.217 89.9066C286.85 89.7109 286.069 89.3587 285.747 89.1631C285.702 89.1631 285.656 89.1631 285.656 89.1631C278.584 92.6458 257.002 103.329 251.997 105.833C251.905 105.872 251.905 105.95 251.997 105.99C252.41 106.185 253.19 106.577 253.466 106.733C253.512 106.733 253.512 106.733 253.558 106.733Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M255.946 107.986C260.721 105.638 258.976 106.459 266.186 102.898C266.277 102.859 266.277 102.781 266.186 102.742C265.818 102.546 265.038 102.194 264.716 101.998C264.67 101.998 264.624 101.998 264.624 101.998C257.553 105.481 259.39 104.581 254.384 107.086C254.292 107.125 254.292 107.203 254.384 107.242C254.798 107.438 255.578 107.829 255.854 107.986C255.854 107.986 255.9 107.986 255.946 107.986Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M267.241 102.39C272.017 100.042 271.053 100.472 278.262 96.9113C278.354 96.8722 278.354 96.7939 278.262 96.7548C277.895 96.5591 277.114 96.2069 276.793 96.0113C276.747 96.0113 276.701 96.0113 276.701 96.0113C269.629 99.494 270.685 99.0244 265.68 101.49C265.588 101.529 265.588 101.607 265.68 101.646C266.093 101.842 266.874 102.233 267.15 102.39C267.196 102.429 267.241 102.429 267.241 102.39Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M279.319 96.4419C284.094 94.0941 282.349 94.9158 289.559 91.3549C289.65 91.3157 289.65 91.2375 289.559 91.1983C289.191 91.0027 288.411 90.6505 288.089 90.4548C288.043 90.4548 287.997 90.4548 287.997 90.4548C280.926 93.9375 282.763 93.0375 277.757 95.5419C277.666 95.5811 277.666 95.6593 277.757 95.6985C278.171 95.8941 278.951 96.2854 279.227 96.4419C279.227 96.4811 279.273 96.4811 279.319 96.4419Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M273.395 105.794C278.17 103.446 277.711 103.642 284.966 100.081C285.058 100.042 285.058 99.9633 284.966 99.9242C284.599 99.7285 279.961 97.3806 279.64 97.2241C279.594 97.2241 279.548 97.2241 279.548 97.2241C272.476 100.707 272.981 100.433 267.976 102.937C267.884 102.976 267.884 103.055 267.976 103.094C268.389 103.289 273.027 105.677 273.303 105.794C273.303 105.794 273.349 105.794 273.395 105.794Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M273.073 55.197L272.385 54.8448C271.558 54.4535 270.594 54.4535 269.767 54.8448C263.935 57.7014 240.471 69.3626 234.317 72.3757C233.078 72.9627 232.343 74.0975 232.343 75.3106C232.389 79.6542 232.481 92.9197 232.527 97.1068C232.527 97.8894 233.032 98.6329 233.858 99.0242L234.547 99.3373L255.486 78.4411L273.165 55.3927C273.211 55.3927 273.165 55.2753 273.073 55.197Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M235.971 73.2366C242.996 69.793 266.461 58.171 272.247 55.2753C272.844 55.0014 273.533 55.3535 273.579 55.9014C273.625 60.0102 273.762 72.7279 273.808 77.9715C273.808 79.4194 272.936 80.7107 271.466 81.4542C263.89 85.2108 240.195 96.9503 235.144 99.4156C234.731 99.6112 234.226 99.3764 234.226 98.946C234.226 95.2285 234.088 81.3368 233.996 76.1715C233.996 74.9584 234.777 73.8627 235.971 73.2366Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M264.854 68.6974L264.9 68.2278L263.66 68.8539C258.746 71.28 246.578 77.3063 243.18 78.9889V79.4585C246.578 77.7759 259.022 71.6322 263.935 69.2061L264.854 68.6974Z"
        fill="#9696A8"
      />
      <path
        d="M243.088 80.8285V81.298C244.695 80.5154 248.231 78.7545 252.042 76.8762V76.4066C248.277 78.2849 244.695 80.0067 243.088 80.8285Z"
        fill="#9696A8"
      />
      <path
        d="M253.328 75.7411V76.2107C257.323 74.215 261.456 72.1802 263.844 71.0062L264.854 70.4975L264.9 70.028L263.66 70.6541C261.272 71.828 257.277 73.8237 253.328 75.7411Z"
        fill="#9696A8"
      />
      <path
        d="M243.088 82.3935V82.863C245.476 81.6891 252.41 78.2455 257.966 75.5063V75.0367C252.455 77.7759 245.568 81.1804 243.088 82.3935Z"
        fill="#9696A8"
      />
      <path
        d="M259.252 74.4106V74.8802C261.043 73.9801 262.65 73.1975 263.844 72.6105L264.854 72.1018L264.9 71.6323L263.66 72.2584C262.466 72.8062 260.951 73.5497 259.252 74.4106Z"
        fill="#9696A8"
      />
      <path
        d="M243.088 84.0762V84.5458C246.486 82.8631 258.93 76.7195 263.844 74.2933L264.854 73.7846L264.9 73.3151L263.66 73.9412C258.7 76.3282 246.532 82.3544 243.088 84.0762Z"
        fill="#9696A8"
      />
      <path
        d="M243.088 85.8371V86.3067C244.695 85.524 248.231 83.7631 252.042 81.8848V81.3761C248.277 83.2935 244.695 85.0153 243.088 85.8371Z"
        fill="#9696A8"
      />
      <path
        d="M253.328 80.7499V81.2195C257.323 79.2238 261.456 77.189 263.844 76.015L264.854 75.5063L264.9 75.0367L263.66 75.6628C261.272 76.8368 257.277 78.8325 253.328 80.7499Z"
        fill="#9696A8"
      />
      <path
        d="M259.252 79.5371V80.0067C261.043 79.1067 262.65 78.324 263.844 77.7371L264.854 77.2284L264.9 76.7588L263.66 77.3849C262.466 77.9719 260.951 78.7154 259.252 79.5371Z"
        fill="#9696A8"
      />
      <path
        d="M243.088 87.5589V88.0284C245.476 86.8545 252.41 83.4109 257.966 80.6717V80.2021C252.455 82.9022 245.568 86.3458 243.088 87.5589Z"
        fill="#9696A8"
      />
      <path
        d="M249.103 154.044L229.083 163.983C229.037 163.983 211.679 155.413 211.679 155.413C211.633 155.374 211.633 155.296 211.679 155.257C211.679 155.257 231.792 145.278 231.838 145.317C231.792 145.317 249.195 154.004 249.103 154.044Z"
        fill="#9696A8"
      />
      <path
        d="M326.203 162.534L325.56 162.887C325.881 163.043 358.392 179.283 360.872 180.613L361.561 180.261C359.081 178.931 326.524 162.691 326.203 162.534Z"
        fill="#E8E8EC"
      />
      <path
        d="M345.396 175.839C351.733 179.009 357.198 181.748 358.162 182.296L358.851 181.944C357.887 181.435 352.468 178.696 346.085 175.487L345.396 175.839Z"
        fill="#E8E8EC"
      />
      <path
        d="M323.448 164.178L322.805 164.53C322.988 164.608 333.55 169.891 343.101 174.665L343.79 174.313C334.239 169.578 323.631 164.256 323.448 164.178Z"
        fill="#E8E8EC"
      />
      <path
        d="M320.187 165.704L319.544 166.056C319.636 166.096 322.621 167.583 326.845 169.696L327.534 169.343C323.309 167.23 320.325 165.743 320.187 165.704Z"
        fill="#E8E8EC"
      />
      <path
        d="M329.783 170.517L329.141 170.83C339.059 175.761 353.294 182.922 354.902 183.783L355.59 183.431C353.937 182.57 339.702 175.409 329.783 170.517Z"
        fill="#E8E8EC"
      />
      <path
        d="M321.977 218.14C321.886 218.179 281.109 198.066 281.109 198.066C280.971 197.987 281.109 182.922 281.109 182.922L321.977 202.996"
        fill="#E8E8EC"
      />
      <path
        d="M321.977 218.14C321.886 218.179 281.109 198.066 281.109 198.066C280.971 197.987 281.109 182.922 281.109 182.922L321.977 202.996"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M243.961 153.965L239.185 156.313L237.808 155.648L241.206 153.965L240.517 153.613L241.206 153.261L241.894 153.613L242.583 153.261L243.961 153.965Z"
        fill="#DFDFF4"
      />
      <path
        d="M237.761 150.873L241.205 152.556L236.383 154.943L232.939 153.26L237.761 150.873ZM236.383 154.278L239.827 152.595L238.45 151.93L235.006 153.613L236.383 154.278Z"
        fill="#DFDFF4"
      />
      <path
        d="M230.873 147.508L234.317 149.191L229.496 151.578L226.052 149.895L230.873 147.508ZM229.496 150.873L232.94 149.191L231.562 148.525L228.118 150.208L229.496 150.873Z"
        fill="#DFDFF4"
      />
      <path
        d="M237.072 150.56L232.251 152.908L230.873 152.243L234.317 150.56L233.628 150.208L234.317 149.856L235.006 150.208L235.695 149.856L237.072 150.56Z"
        fill="#DFDFF4"
      />
      <path
        d="M224.949 153.143L228.393 154.826L223.572 157.213L220.128 155.531L224.949 153.143ZM223.618 156.548L227.062 154.865L225.684 154.2L222.24 155.883L223.618 156.548Z"
        fill="#DFDFF4"
      />
      <path
        d="M224.261 152.83L219.439 155.178L218.062 154.513L221.505 152.83L220.817 152.478L221.505 152.126L222.194 152.478L222.883 152.126L224.261 152.83Z"
        fill="#DFDFF4"
      />
      <path
        d="M231.837 156.548L235.281 158.23L230.46 160.617L227.016 158.935L231.837 156.548ZM230.505 159.952L233.949 158.269L232.572 157.604L229.128 159.287L230.505 159.952Z"
        fill="#DFDFF4"
      />
      <path
        d="M231.148 156.196L226.373 158.583L224.995 157.918L228.393 156.196L227.704 155.883L228.393 155.531L229.082 155.883L229.771 155.531L231.148 156.196Z"
        fill="#DFDFF4"
      />
      <path
        d="M245.338 126.494C245.2 126.534 245.016 126.534 244.879 126.455C244.373 126.299 243.822 125.829 243.593 125.203C243.317 124.381 243.455 123.599 244.006 123.481C244.557 123.364 245.476 124.029 245.705 124.851C245.981 125.673 245.889 126.338 245.338 126.494Z"
        fill="#9696A8"
      />
      <path
        d="M194.23 189.066C194.184 189.066 176.826 180.496 176.826 180.496C176.78 180.457 176.826 173.57 176.826 173.57L194.23 182.14L214.159 172.24"
        fill="#E8E8EC"
      />
      <path
        d="M194.23 189.066C194.184 189.066 176.826 180.496 176.826 180.496C176.78 180.457 176.826 173.57 176.826 173.57L194.23 182.14L214.159 172.24"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M292.773 127.277C292.268 128.804 290.615 130.056 289.559 129.821C288.503 129.586 288.273 128.138 288.778 126.612C289.146 125.477 290.202 124.577 291.166 124.303C291.488 124.225 291.809 124.186 292.039 124.264C293.095 124.46 293.232 125.751 292.773 127.277Z"
        fill="#9696A8"
      />
      <path
        d="M194.229 189.027V182.14L214.159 172.239C214.205 172.2 214.296 172.239 214.296 172.278V179.087L194.229 189.027Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M184.219 181.983C184.127 182.022 183.989 181.983 183.851 181.944C183.438 181.827 182.979 181.435 182.841 180.966C182.611 180.301 182.749 179.714 183.163 179.596C183.622 179.479 184.311 180.027 184.494 180.692C184.724 181.318 184.632 181.866 184.219 181.983Z"
        fill="#9696A8"
      />
      <path
        d="M170.811 53.319L164.704 56.3712L157.953 53.0451C157.907 53.0451 157.907 53.0059 157.953 53.0059L164.107 49.9537C164.107 49.9537 170.857 53.319 170.811 53.319Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M166.311 69.8325L161.903 72.0239L156.989 69.6369V69.5977L161.443 67.4064L166.311 69.8325Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M9.35745 88.5764L5.40832 90.533L1 88.3807V88.3416C1 88.3416 4.99509 86.3459 4.99509 86.385L9.35745 88.5764Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M49.5827 152.556L43.1539 155.765L36.0364 152.282C35.9905 152.282 35.9905 152.243 36.0364 152.204L42.5111 148.995L49.5827 152.556Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M274.956 173.53L271.099 175.448L266.828 173.335V173.296C266.828 173.296 270.685 171.378 270.731 171.378C270.731 171.378 274.956 173.491 274.956 173.53Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M362.892 126.573L356.693 129.664L349.805 126.299C349.759 126.299 349.759 126.26 349.805 126.26L356.05 123.169C356.05 123.129 362.938 126.573 362.892 126.573Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M343.928 103.133L340.116 105.051L335.892 102.977V102.937L339.749 101.02C339.703 101.02 343.928 103.133 343.928 103.133Z"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M70.3389 77.8149C70.3389 77.8149 102.207 93.7023 102.069 93.7806"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M143.305 188.635C143.305 188.635 153.82 193.879 155.29 194.662C155.381 194.701 155.519 194.701 155.657 194.662L183.714 180.77"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M291.212 127.356C291.212 127.356 363.627 163.474 363.352 163.591L350.31 169.931"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M244.649 125.517L240.379 127.669C240.379 127.669 262.558 138.743 262.466 138.782L240.379 149.582"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M229.082 170.909C229.036 170.909 211.679 162.339 211.679 162.339C211.633 162.3 211.679 155.413 211.679 155.413L229.082 163.983L249.011 154.083"
        fill="#E8E8EC"
      />
      <path
        d="M229.082 170.909C229.036 170.909 211.679 162.339 211.679 162.339C211.633 162.3 211.679 155.413 211.679 155.413L229.082 163.983L249.011 154.083"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M229.082 170.87V163.983L249.011 154.082C249.057 154.043 249.149 154.082 249.149 154.121V160.93L229.082 170.87C229.082 170.909 229.082 170.909 229.082 170.87Z"
        fill="#E8E8EC"
      />
      <path
        d="M229.082 170.87V163.983L249.011 154.082C249.057 154.043 249.149 154.082 249.149 154.121V160.93L229.082 170.87ZM229.082 170.87C229.082 170.909 229.082 170.909 229.082 170.87Z"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M321.978 218.063V202.958L368.724 179.714C368.861 179.636 368.999 179.714 368.999 179.831V194.701L322.024 218.024C322.024 218.102 321.978 218.102 321.978 218.063Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M179.673 128.177C179.443 128.96 178.571 129.586 178.02 129.469C177.515 129.351 177.377 128.608 177.607 127.825C177.79 127.238 178.341 126.769 178.801 126.651C178.984 126.612 179.122 126.573 179.26 126.612C179.857 126.729 179.949 127.395 179.673 128.177Z"
        fill="#9696A8"
      />
      <path
        d="M146.794 144.534C146.565 145.317 145.692 145.943 145.141 145.826C144.636 145.708 144.498 144.965 144.728 144.182C144.911 143.595 145.462 143.125 145.922 143.008C146.105 142.969 146.243 142.93 146.381 142.969C146.932 143.125 147.024 143.752 146.794 144.534Z"
        fill="#9696A8"
      />
      <path
        d="M178.663 128.451C178.663 128.451 221.965 150.052 221.827 150.13"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M146.105 144.848C146.105 144.848 190.051 166.761 189.867 166.839"
        stroke="#9696A8"
        stroke-width="0.344397"
        stroke-miterlimit="10"
      />
      <path
        d="M116.855 88.2239L135.912 79.4976L155.382 88.2239L135.912 97.2632L116.855 88.2239Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M135.912 56.8802V94.9943L155.382 85.4462L135.912 56.8802Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M116.855 85.7199L135.912 94.994V56.88L116.855 85.7199Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M116.855 88.2245L135.912 97.2639V114.13L116.855 88.2245Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
      <path
        d="M135.912 97.2639L155.382 88.2245L135.912 114.13V97.2639Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.459196"
        stroke-miterlimit="10"
      />
    </svg>
  );
}
