import DappLauncherIcon from "../../icons/DappLauncherIcon";
import DevModeIcon from "../../icons/DevModeIcon";
import PluginIcon from "../../icons/PluginIcon";
import styles from "./index.module.scss";

export const MoreFeatures = () => {
  return (
    <div className={styles.more}>
      <h2 className={styles.more_heading}>More from us</h2>
      <div className={styles.more_divs}>
        <div className={styles.more_divs_main}>
          <div className={styles.more_divs_div1}>
            <p>Dev Mode</p>
            <small>
              Empower your development with Dev Mode. Design complex workflows
              and export them as executable interactions in your language of
              choice. Ideal for developers who want to customize and control
              every aspect of their Dapp, from logic to deployment."
            </small>
            <div className={styles.more_divs_div1_icon}>
              <DevModeIcon />
            </div>
          </div>
          <div className={styles.more_divs_div2}>
            <p>Plugin</p>
            <small>
              Transform your application into modular, reusable blocks. With our
              Plugin feature, you can bundle your entire application into
              actions, blocks, or triggers. Easily integrate these into
              workflows, allowing for dynamic, scalable, and automated processes
              that fit your unique needs
            </small>
            <div className={styles.more_divs_div2_icon}>
              <PluginIcon />
            </div>
          </div>
        </div>

        <div className={styles.more_divs_div3}>
          <p>Dapp Launcher</p>
          <small>
            Effortlessly launch your decentralized application with our Dapp
            Launcher. Our Dapp Launcher is shopify meets defi, you can quickly
            build frontends, link actions to smart contracts, and index
            transactions. Ideal for developers who want to move from concept to
            deployment in record time
          </small>
          <div className={styles.more_divs_div3_icon}>
            <DappLauncherIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
