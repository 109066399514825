import React from "react";
import styles from "./index.module.scss"; // Adjust the path as needed

// Define the types for the props
interface AccordionItemProps {
  header: string;
  body: string;
  isActive: boolean;
  onClick: () => void;
}

// Accordion Item Component
const AccordionItem: React.FC<AccordionItemProps> = ({
  header,
  body,
  isActive,
  onClick,
}) => {
  return (
    <div className={styles.accordion_item}>
      <div
        className={`${styles.accordion_item_header} ${
          isActive ? styles.active : ""
        }`}
        onClick={onClick}
      >
        {header}
      </div>
      <div
        className={styles.accordion_item_body}
        style={{ maxHeight: isActive ? "200px" : "0" }} // Adjust "200px" based on content
      >
        <div className={styles.accordion_item_body_content}>{body}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
