export default function PluginIcon() {
  return (
    <svg
      width="333"
      height="174"
      viewBox="0 0 333 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M269.302 58.6784C269.285 59.1857 269.017 59.6131 268.416 59.8857L186.058 98.2512C183.737 99.3441 180.861 99.2603 178.616 98.0342L110.161 61.001C109.496 60.619 109.095 59.9544 109.114 59.3746L109.694 41.6918L269.798 40.9931L269.218 58.6759L269.302 58.6784Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M179.145 80.5102L110.703 43.5105C109.289 42.7446 109.346 41.0068 110.808 40.3247L175.271 10.3146C188.164 4.31264 203.892 4.77099 216.366 11.5123L268.924 39.9319C270.004 40.5432 269.961 41.8465 268.843 42.3937L186.501 80.7246C184.265 81.819 181.39 81.7352 179.145 80.5102Z"
        fill="#9696A8"
      />
      <path
        d="M130.74 54.3265C135.664 56.4263 138.963 53.914 138.963 53.914C141.913 51.6814 146.372 49.9275 151.558 49.2091C161.674 47.8374 170.621 51.1412 171.631 56.4599C172.421 60.7578 167.879 65.0452 160.865 67.3043C159.667 67.7041 159.035 68.9174 159.596 69.8757L159.594 69.9481"
        fill="#E8E8EC"
      />
      <path
        d="M130.74 54.3265C135.664 56.4263 138.963 53.914 138.963 53.914C141.913 51.6814 146.372 49.9275 151.558 49.2091C161.674 47.8374 170.621 51.1412 171.631 56.4599C172.421 60.7578 167.879 65.0452 160.865 67.3043C159.667 67.7041 159.035 68.9174 159.596 69.8757L159.594 69.9481"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M242.68 54.7557C237.628 56.5648 234.5 53.8652 234.5 53.8652C231.702 51.4651 227.367 49.455 222.238 48.4361C212.231 46.478 203.084 49.2546 201.727 54.5043C200.657 58.748 204.908 63.2916 211.761 65.9548C212.93 66.4236 213.481 67.6714 212.859 68.5952L212.856 68.6675"
        fill="#E8E8EC"
      />
      <path
        d="M242.68 54.7557C237.628 56.5648 234.5 53.8652 234.5 53.8652C231.702 51.4651 227.367 49.455 222.238 48.4361C212.231 46.478 203.084 49.2546 201.727 54.5043C200.657 58.748 204.908 63.2916 211.761 65.9548C212.93 66.4236 213.481 67.6714 212.859 68.5952L212.856 68.6675"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M212.785 68.5391L212.243 86.0656"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M159.708 69.9291L159.161 87.5998"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M146.365 68.6009C146.365 68.6009 151.786 65.9836 160.973 67.3493L146.365 68.6009Z"
        fill="#E8E8EC"
      />
      <path
        d="M146.365 68.6009C146.365 68.6009 151.786 65.9836 160.973 67.3493"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M212.354 66.2885C212.354 66.2885 222.214 65.1235 226.021 70.3925L212.354 66.2885Z"
        fill="#E8E8EC"
      />
      <path
        d="M212.354 66.2885C212.354 66.2885 222.214 65.1235 226.021 70.3925"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M98.9908 138.769L72.6841 151.056C70.3627 152.149 67.4873 152.065 65.2419 150.839L8.60095 120.177C5.60637 118.567 3.9982 115.981 3.74791 113.291L4.30915 96.1908L18.3515 99.0662L102.539 108.048L156.402 94.3885L155.843 111.416C155.826 111.923 155.558 112.35 154.957 112.623L140.688 119.242L140.171 134.965C140.171 134.965 139.848 139.67 134.497 142.778C133.203 143.538 130.184 142.652 127.851 144.107C122.665 147.364 117.09 146.984 112.811 145.844C109.369 144.946 106.645 142.836 104.696 140.313C103.717 139.197 102.059 138.133 98.9908 138.769Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M145.721 88.1216L146.365 68.5354L123.386 78.139L143.738 89.2936L145.721 88.1216Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6811 133.923L10.055 103.294C3.40292 99.6953 3.67872 91.2995 10.5567 88.0224L79.8268 55.7732C82.2345 54.6118 85.2782 54.7004 87.6051 55.9998L156.537 93.2899C157.617 93.9009 157.575 95.2037 156.456 95.7507L74.1237 134.067C71.8029 135.159 69.0127 135.077 66.6811 133.923Z"
        fill="#9696A8"
      />
      <path
        d="M225.436 70.211C225.185 70.1314 222.198 65.9243 213.226 66.5303C213.226 66.5303 214.034 67.6381 213.323 68.7016L212.739 86.4662L225.518 80.5498L225.436 70.211Z"
        fill="white"
      />
      <path
        d="M105.376 66.3204C110.301 68.4201 113.599 65.9079 113.599 65.9079C116.55 63.6753 121.008 61.9214 126.195 61.203C136.311 59.8313 145.258 63.1351 146.268 68.4538C147.058 72.7516 142.516 77.0391 135.502 79.2982C134.304 79.698 133.672 80.9113 134.233 81.8696L134.231 81.942"
        fill="#9696A8"
      />
      <path
        d="M99.577 121.835C103.444 120.064 104.964 122.716 104.964 122.716C107.187 127.201 115.397 129.758 124.578 128.504C134.694 127.133 142.064 121.769 141.056 116.378C140.334 112.589 135.685 109.845 129.363 109.081C129.363 109.081 128.114 108.465 129.488 107.853"
        fill="#9696A8"
      />
      <path
        d="M146.735 68.7347C146.735 68.7347 152.553 66.4466 159.779 67.5967C159.779 67.5967 158.555 68.8622 159.277 70.0397L158.695 87.7321L146.327 81.1555L146.735 68.7347Z"
        fill="white"
      />
      <path
        d="M268.878 139.042L294.329 152.839C296.574 154.065 299.449 154.149 301.771 153.056L360.314 125.752C363.409 124.319 365.184 121.833 365.61 119.162L366.171 102.062L351.967 104.114L267.352 108.174L214.487 91.4016L213.928 108.429C213.912 108.936 214.151 109.378 214.734 109.685L228.542 117.123L228.026 132.846C228.026 132.846 227.864 137.773 233.177 140.974C244.469 147.686 252.36 147.118 256.984 145.513C259.467 144.642 261.385 142.957 262.638 140.891C263.442 139.609 265.186 138.064 268.878 139.042Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M225.563 85.5785L226.199 66.2095C226.204 66.065 226.376 65.9976 226.458 66.0724L248.259 76.835L227.212 86.8564L225.563 85.5785Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M301.255 136.078L359.783 108.804C366.659 105.6 366.935 97.2038 360.285 93.5326L293.264 57.3125C290.937 56.0131 287.894 55.9245 285.486 57.0859L214.238 90.2918C213.12 90.8388 213.077 92.1415 214.157 92.7526L293.817 135.789C296.062 137.013 298.937 137.097 301.255 136.078Z"
        fill="#9696A8"
      />
      <path
        d="M266.979 66.1922C261.926 68.0013 258.799 65.3018 258.799 65.3018C256 62.9017 251.666 60.8915 246.537 59.8726C236.53 57.9146 227.383 60.6912 226.026 65.9408C224.955 70.1845 229.207 74.7282 236.059 77.3913C237.229 77.8601 237.78 79.1079 237.157 80.0317L237.155 80.1041"
        fill="#9696A8"
      />
      <path
        d="M214.19 124.429L213.533 144.44L198.845 141.265C198.845 141.265 196.462 128.902 196.906 128.264C197.35 127.627 198.605 125.421 198.605 125.421L211.066 124.338L214.19 124.429Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M269.389 121.96C265.646 119.967 263.954 122.526 263.954 122.526C261.441 126.873 253.079 128.947 243.998 127.161C233.991 125.203 226.988 119.42 228.347 114.098C229.316 110.359 234.137 107.891 240.497 107.497C240.497 107.497 241.784 106.955 240.453 106.264"
        fill="#9696A8"
      />
      <path
        d="M261.229 168.97C261.208 169.622 260.85 170.192 260.077 170.532L195.598 200.579C182.702 206.588 166.971 206.129 154.493 199.38L101.923 170.926C101.341 170.618 101.101 170.176 101.118 169.669L101.708 151.691L261.831 150.629L261.229 168.97Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M157.436 125.52L156.778 145.532L171.646 143.217C171.646 143.217 174.836 131.016 174.435 130.354C174.034 129.691 172.925 127.417 172.925 127.417L160.56 125.611L157.436 125.52Z"
        fill="#E8E8EC"
        stroke="#9696A8"
        stroke-width="0.846784"
        stroke-miterlimit="10"
      />
      <path
        d="M260.636 149.083L242.046 138.971C238.714 137.498 237.111 139.853 237.111 139.853C234.591 144.219 226.232 146.316 217.161 144.539C207.167 142.591 200.18 136.799 201.549 131.455C202.525 127.701 207.347 125.216 213.703 124.809C213.703 124.809 214.732 124.401 213.991 123.799L192.413 112.078C190.173 110.853 187.3 110.774 184.979 111.873L157.559 124.716C156.607 125.344 157.69 125.81 157.69 125.81C163.919 126.636 168.646 129.312 169.36 133.113C170.358 138.449 162.981 143.917 152.87 145.311C143.696 146.512 135.498 143.959 133.284 139.535C133.284 139.535 131.849 137.023 128.246 138.524L102.717 150.473C101.599 151.024 101.553 152.331 102.631 152.943L155.086 181.368C167.535 188.11 183.248 188.543 196.142 182.5L260.609 152.281C261.986 151.592 262.047 149.849 260.636 149.083Z"
        fill="#9696A8"
      />
      <path
        d="M249.399 51.6055L239.778 55.9614L245.087 56.6232L249.399 51.6055Z"
        fill="#E8E8EC"
      />
      <path
        d="M125.39 51.3237L134.286 56.1468L127.175 56.2294L125.39 51.3237Z"
        fill="#E8E8EC"
      />
    </svg>
  );
}
