import FlashIcon from "../../icons/FlashIcon";
import styles from "./index.module.scss";
import Feature1 from "./../../assets/feature1.png";
import Feature2 from "./../../assets/feature2.png";
import Feature3desk from "./../../assets/feature3.png";
import Feature3mob from "./../../assets/feature3small.png";
import Feature4 from "./../../assets/feature4.png";

export const Features = () => {
  return (
    <div className={styles.features}>
      {/* Feature 1 */}
      <div className={styles.features_sect1}>
        <div className={styles.features_sect1_text}>
          <div className={styles.features_sect1_floattext}>
            <div>
              <span>
                <FlashIcon />
              </span>
              <p>What is Dropdefi</p>
            </div>
          </div>
          <h2>Unlock the Power of Web3 with Seamless UX</h2>
          <p>
            Whether you’re a seasoned crypto enthusiast or completely new to the
            world of Web3, Dropdefi makes it for anyone to participate,
            transact, and innovate without writing a single line of code.
          </p>
        </div>
        <div className={styles.features_sect1_img}>
          <img src={Feature1} alt="Feature Image" />
        </div>
      </div>
      {/* Feature 2 */}
      <div className={`${styles.features_sect1} ${styles.features_sect2}`}>
        <div className={styles.features_sect1_text}>
          <div className={styles.features_sect1_floattext}>
            <div>
              <span>
                <FlashIcon />
              </span>
              <p>What is Dropdefi</p>
            </div>
          </div>
          <h2>Build custom interaction blocks</h2>
          <p>
            Create personalized interaction blocks such as price action blocks,
            Interchain asset buyer, Token sniffers etc. Seamlessly chain these
            blocks together to create powerful, automated workflows tailored to
            your specific needs.
          </p>
        </div>
        <div className={styles.features_sect1_img}>
          <img src={Feature2} alt="Feature Image" />
        </div>
      </div>
      {/* Feature 3 */}
      <div className={styles.features_sect3}>
        <div className={styles.features_sect3_text}>
          <h2>Automate workflows</h2>
          <p>
            With our secure private key managers but not limited to - turnkey,
            dropdefi mpc key manager, etc, you can setup automated actions, sign
            intent for actions and have dropdefi manage and run your workflows
          </p>
        </div>
        <div className={styles.features_sect3_img}>
          <img
            src={Feature3desk}
            alt="Feature Image"
            className={styles.sect3_deskimg}
          />
          <img
            src={Feature3mob}
            alt="Feature Image"
            className={styles.sect3_mobimg}
          />
        </div>
      </div>
      {/* features 4 */}
      <div className={styles.features_sect4}>
        <div className={styles.features_sect4_text}>
          <h2>Explore our prebuilt actions Library</h2>
          <p>
            Whether you need to automate onchain/offchain transactions, Airdrop
            distributions, manage smart contracts, or perform other crucial
            tasks, our library offers a diverse range of customizable actions.
          </p>
        </div>
        <div className={styles.features_sect4_img}>
          <img src={Feature4} alt="Feature Image" />
        </div>
      </div>
    </div>
  );
};
