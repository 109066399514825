import Accordion from "./accordion";
import styles from "./index.module.scss";

export const Faq = () => {
  return (
    <div className={styles.faq}>
      <div className={styles.faq_div1}>
        <h2>Frequently asked questions</h2>
        <p>Got questions? We got answers!</p>
      </div>
      <div className={styles.faq_div2}>
        <div className={styles.faq_accordion}>
          <div className={styles.accordion}>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};
