import styles from "./index.module.scss";
import PlayButton from "../../icons/PlayButton";

export const Button = () => {
  return (
    <button className={styles.button}>
      <span>
        <PlayButton />
      </span>
      Watch Demo
    </button>
  );
};
