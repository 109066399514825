import React, { useState } from "react";
import styles from "./index.module.scss"; // Adjust the path as needed
import AccordionItem from "./accordionItem";

// Define the type for the FAQ item
interface FaqItem {
  header: string;
  body: string;
}

// Accordion Component
const Accordion: React.FC = () => {
  // Array of objects containing FAQ data
  const faqItems: FaqItem[] = [
    {
      header: "When launch? 🚀",
      body: "We are currently testing out the application with a select private group. A beta version should be live in the next couple of weeks.",
    },
    {
      header: "What exactly are custom actions?",
      body: "Answer: Custom actions are onchain and offchain interactions with onchain entities like smart contract and offchain entities like social platforms (e.g twitter). With our interactive studio, you can customize different kinds of actions as you deem fit.",
    },
    {
      header: "How do i use the app",
      body: "Answer:  You can either build a custom action or use a pre built actions. If custom action is the choice, you can use our interactive studio to build any kind of interaction. If pre built actions is the choice, you can select the action of choice and provide all requirements for selected action.",
    },
    {
      header: "What exactly are custom actions?",
      body: "Answer: Custom actions are onchain and offchain interactions with onchain entities like smart contract and offchain entities like social platforms (e.g twitter). With our interactive studio, you can customize different kinds of actions as you deem fit.",
    },
  ];

  // State to manage which accordion item is active
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // Handle click to toggle accordion item
  const handleItemClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.accordion}>
      {faqItems.map((item, index) => (
        <AccordionItem
          key={index}
          header={item.header}
          body={item.body}
          isActive={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
