export default function FlashIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.201 5.80657H8.5272V1.90657C8.5272 0.99657 8.03428 0.812404 7.43303 1.4949L6.9997 1.98782L3.33262 6.15865C2.82887 6.7274 3.04012 7.19324 3.79845 7.19324H5.4722V11.0932C5.4722 12.0032 5.96512 12.1874 6.56637 11.5049L6.9997 11.012L10.6668 6.84115C11.1705 6.2724 10.9593 5.80657 10.201 5.80657Z"
        fill="#1B1A41"
      />
    </svg>
  );
}
