import DiscordIcon from "../../icons/DiscordIcon";
import LogoIcon from "../../icons/LogoIcon";
import TgIcon from "../../icons/TgIcon";
import XIcon from "../../icons/XIcon";
import styles from "./index.module.scss";

export const Footer = () => {
  return (
    <div className={styles.footer_cont}>
      <div className={styles.footer}>
        <div className={styles.footer_img}>
          <h2>Onboarding users one template at a time.</h2>
          <p>Get started today</p>
          <button className={styles.footer_button}>Launch app</button>
        </div>
      </div>

      <div className={styles.footer_navmain}>
        <div className={styles.footer_nav}>
          <div className={styles.footer_nav_logo}>
            <a href="/">
              <LogoIcon />
            </a>
          </div>
          <div className={styles.footer_navitems}>
            <a href="/">
              <DiscordIcon />
            </a>
            <a href="https://x.com/dropdefi_io">
              <XIcon />
            </a>
            <a href="/">
              <TgIcon />
            </a>
          </div>
        </div>
        <hr className={styles.footer_nav_hr} />
        <div className={styles.bigtextdiv}>
          <p className={styles.footer_bigtext}>DROPDEFI</p>
        </div>
      </div>
    </div>
  );
};
